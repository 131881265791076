import {useEffect, useState} from 'react';

export default function useKonami() {
  const [konami, setKonami] = useState(false);
  useEffect(() => {
    const pattern = [
      'ArrowUp',
      'ArrowUp',
      'ArrowDown',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'ArrowLeft',
      'ArrowRight',
      'b',
      'a',
    ];
    let current = 0;

    const keyHandler = function (event) {
      // If the key isn't in the pattern, or isn't the current key in the pattern, reset
      if (pattern.indexOf(event.key) < 0 || event.key !== pattern[current]) {
        current = 0;
        return;
      }

      // Update how much of the pattern is complete
      current++;

      // If complete, alert and reset
      if (pattern.length === current) {
        current = 0;
        setKonami(true);
      }
    };

    // Listen for keydown events
    document.addEventListener('keydown', keyHandler, false);

    return () => document.removeEventListener('keydown', keyHandler);
  }, []);

  return [konami, setKonami];
}
