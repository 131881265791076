import * as React from 'react';
import {graphql} from 'gatsby';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Autoplay, Pagination} from 'swiper';
import Seo from '../components/Seo';
import Layout from '../components/Layout';
import bgVideo from '../images/bg-home.mp4';
import Image from '../components/Image';
import useKonami from '../components/useKonami';

SwiperCore.use([Autoplay, Pagination]);

function Button({children, href, ...props}) {
  return (
    <a
      href={href}
      className="px-4 py-2 rounded-xl shadow-2xl bg-brand text-white inline-block cursor-pointer"
      {...props}
    >
      {children}
    </a>
  );
}

function Heading({title, children}) {
  return (
    <div className="text-center pb-8">
      <h2 className="text-brand text-5xl mb-4 font-bold">{title}</h2>
      <p className="lg:w-1/2 mx-auto">{children}</p>
    </div>
  );
}

function HowToItem({children, img, step, title}) {
  return (
    <div className="text-center w-full">
      <p className="text-brand pb-2 font-bold ">{step}.</p>
      <div className="aspect-video rounded-2xl border-white border overflow-hidden shadow shadow-white">
        <Image image={img} className="h-full w-full object-contain" />
      </div>
      <p className=" pt-2 font-bold">{title}</p>
      <p className="mx-auto pb-10 font-light">{children}</p>
    </div>
  );
}

function Section({children, light, id}) {
  return (
    <section
      id={id}
      className={`py-16 px-8 ${light ? 'bg-white text-dark' : 'bg-dark text-white'}`}
    >
      {children}
    </section>
  );
}

export default function Index({
  data: {
    images: {nodes: images},
  },
}) {
  const [konami] = useKonami();

  const cele = images.find(({relativePath}) => relativePath === 'cele.jpg');

  return (
    <Layout>
      <div className="min-h-screen flex items-end relative justify-center px-8">
        {konami ? (
          <Image image={cele} className="absolute inset-0 object-cover w-full h-full" />
        ) : (
          <video
            src={bgVideo}
            alt=""
            className="absolute inset-0 object-cover w-full h-full rotate-180"
            autoPlay
            muted
            playsInline
            loop
          />
        )}
        <div className="relative text-white text-5xl lg:text-9xl leading-tight font-bold mb-8 w-full">
          <div className="w-full  lg:pb-8 flex-grow">
            <h2 className="pb-8">
              These are bacteria
              <br /> on your hands.
            </h2>
            <h1 className="mb-12">Wash your hands.</h1>
          </div>

          <a href="#how-to" className="block">
            <svg
              width="64"
              height="33"
              viewBox="0 0 64 33"
              fill="none"
              className="mx-auto w-12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2 2L32 30L62 2" stroke="white" strokeWidth="4" strokeLinecap="round" />
            </svg>
          </a>
        </div>
        <div />
      </div>
      <Section id="how-to">
        <Heading title="How to wash">
          Washing your hands is one of the easiest ways to protect yourself and others from
          illnesses, and it only takes the time needed to sing happy birthday twice.
        </Heading>
        <div className="grid lg:grid-cols-4 gap-x-8">
          <HowToItem
            step="01"
            title="Soap them up!"
            img={konami ? cele : images.find(({relativePath}) => relativePath === 'mani1.png')}
          >
            Wet your hands with water and cover every inch of them in soap!{' '}
          </HowToItem>
          <HowToItem
            title="High-five yourself!"
            step="02"
            img={konami ? cele : images.find(({relativePath}) => relativePath === 'mani4.png')}
          >
            You’re washing your hands and that’s great! Start by rubbing your palms together.{' '}
          </HowToItem>
          <HowToItem
            step="03"
            title="A little backscrub now!"
            img={konami ? cele : images.find(({relativePath}) => relativePath === 'mani2.png')}
          >
            Place your right palm over the back of your left hand, let your right hand fingers slip
            between the left ones and rub them together.{' '}
          </HowToItem>
          <HowToItem
            step="04"
            title="You’re doing great, you should high five yourself again!"
            img={konami ? cele : images.find(({relativePath}) => relativePath === 'mani5.png')}
          >
            With your palms touching, let your right fingers slip between the left ones and rub some
            more.
          </HowToItem>
        </div>
        <div className="grid lg:grid-cols-3 lg:w-9/12 mx-auto gap-x-8">
          <HowToItem
            step="05"
            title="Congratulations, you deserve a good hanshake. "
            img={konami ? cele : images.find(({relativePath}) => relativePath === 'mani 8.png')}
          >
            Place the back of your fingers over the opposing palm, squeeze them together and - you
            guessed it - rub rub rub.{' '}
          </HowToItem>
          <HowToItem
            step="06"
            title="Uhm, it looks like we forgot somebody, give a good hug to your thumbs!"
            img={konami ? cele : images.find(({relativePath}) => relativePath === 'mani6.png')}
          >
            Grab your right thumb with the left hand and rub it rotating your hand.
          </HowToItem>
          <HowToItem
            step="07"
            title="Hurray! We’re almost done"
            img={konami ? cele : images.find(({relativePath}) => relativePath === 'mani7.png')}
          >
            Ok your palms worked a lot, give them one last good rub with your fingers and we’re good
            to go!
          </HowToItem>
        </div>
      </Section>
      <Section light id="stickers">
        <Heading title="Sticker set">
          Download the sticker set about washing hands, available for whatsapp and telegram, and
          share it with your friends.
        </Heading>
        <div className="lg:w-1/3 mx-auto">
          <Swiper pagination autoplay loop>
            <SwiperSlide className="aspect-square">
              <Image
                image={
                  konami
                    ? cele
                    : images.find(({relativePath}) => relativePath === 'StickersArtboard 10.png')
                }
              />
            </SwiperSlide>
            <SwiperSlide className="aspect-square">
              <Image
                image={
                  konami
                    ? cele
                    : images.find(({relativePath}) => relativePath === 'StickersArtboard 11.png')
                }
              />
            </SwiperSlide>
            <SwiperSlide className="aspect-square">
              <Image
                image={
                  konami
                    ? cele
                    : images.find(({relativePath}) => relativePath === 'StickersArtboard 9.png')
                }
              />
            </SwiperSlide>
            <SwiperSlide className="aspect-square">
              <Image
                image={
                  konami
                    ? cele
                    : images.find(({relativePath}) => relativePath === 'StickersArtboard 2.png')
                }
              />
            </SwiperSlide>
            <SwiperSlide className="aspect-square">
              <Image
                image={
                  konami
                    ? cele
                    : images.find(({relativePath}) => relativePath === 'StickersArtboard 3.png')
                }
              />
            </SwiperSlide>
            <SwiperSlide className="aspect-square">
              <Image
                image={
                  konami
                    ? cele
                    : images.find(({relativePath}) => relativePath === 'StickersArtboard 4.png')
                }
              />
            </SwiperSlide>
            <SwiperSlide className="aspect-square">
              <Image
                image={
                  konami
                    ? cele
                    : images.find(({relativePath}) => relativePath === 'StickersArtboard 5.png')
                }
              />
            </SwiperSlide>
            <SwiperSlide className="aspect-square">
              <Image
                image={
                  konami
                    ? cele
                    : images.find(({relativePath}) => relativePath === 'StickersArtboard 6.png')
                }
              />
            </SwiperSlide>
            <SwiperSlide className="aspect-square">
              <Image
                image={
                  konami
                    ? cele
                    : images.find(({relativePath}) => relativePath === 'StickersArtboard 7.png')
                }
              />
            </SwiperSlide>
            <SwiperSlide className="aspect-square">
              <Image
                image={
                  konami
                    ? cele
                    : images.find(({relativePath}) => relativePath === 'StickersArtboard 8.png')
                }
              />
            </SwiperSlide>
          </Swiper>
        </div>

        <p className="text-center pt-4">
          <Button download href="./stickers.zip">
            Download
          </Button>
        </p>
      </Section>
      <Section id="reactions">
        <Heading title="Reactions">
          People’s reaction when see the stickers set and their answers to our questions.
        </Heading>
        <div className="grid lg:grid-cols-2 gap-12 lg:w-3/4 mx-auto">
          <div className="">
            <div className="rounded-3xl border border-white p-4 lg:p-8 w-auto text-sm  shadow-2xl ">
              <h3 className="font-bold mb-4">Questions</h3>
              <p className="mb-4">Have you read the stickers in the toilets?</p>
              <p className="mb-4">What risks did you perceive?</p>
              <p className="mb-4">Would you know how to avoid them?</p>
              <p className="mb-4">
                Is it clear what the suggestions are and why they should be followed?
              </p>
              <p className="">How effective/useful do you think the message is from 1 to 10?</p>
            </div>
          </div>
          <div className="grid gap-8 -mr-6 lg:w-8/12 ml-auto">
            <Image image={images.find(({relativePath}) => relativePath === 'Group 5.png')} />
            <Image image={images.find(({relativePath}) => relativePath === 'Group 6.png')} />
            <Image image={images.find(({relativePath}) => relativePath === 'Group 7.png')} />
          </div>
        </div>
      </Section>
      <Section light id="get-involved">
        <Heading title="Get involved">
          We belive in iterative design. Tell us what do you think.
        </Heading>
        <p className="text-center pt-4">
          <Button>Write us</Button>
        </p>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  {
    images: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;
